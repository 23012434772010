<template>
  <div class="index" :style="{'background-color':main_color}" @click="indexOut">
    <div class="header">
        <div class="header_left">
            <label for="membertel" class="membertel_label">录入会员：</label>
            <input type="text" id="membertel" class="membertel_input" placeholder="输入用户手机号" v-model="membertel"
            @keypress.enter="searchMember">
            <button class="membertel_search" @click="searchMember">搜索</button>
        </div>
        <div class="header_right">
            <div class="goods_inputbox">
                <img :src="imageHttp+'search.png'" class="goods_search">
                <input type="text" class="goods_input" placeholder="输入商品编码" v-model="goodscode" @keydown.enter="searchGoods">
                <img :src="imageHttp+'scan.png'" class="goods_scan">
            </div>
            <button class="logout" @click="backToadmin">退出收银</button>
        </div>

    </div>
    <div class="main">
        <div class="left">
            <div class="left_box">
                <div class="l_member" :style="{'color':main_color}" v-if="memberinfo">
                    <div class="l_member_bg" :style="{'background-color':main_color}"></div>
                    <div class="l_member_item">
                        <div class="l_member_item_text" style="margin-bottom:8px">会员信息</div>
                        <div class="l_member_item_text">{{memberinfo.mobile}}</div>
                    </div>
                    <div class="l_member_item">
                        <div class="l_member_item_text" style="margin-bottom:8px">余额(元)</div>
                        <div class="l_member_item_text">{{memberinfo.balance}}</div>
                    </div>
                </div>
                <div class="l_title">
                    <div>消费明细（{{allnum}}）</div>
                    <div :style="{'color':main_color}" class="l_clear" @click="clearGoods" v-if="order_goods.length>0 || memberinfo">清空</div>
                </div>
                <div class="l_goods">
                    <div class="l_goods_item" v-for="(item,index) in order_goods" :key="item.id">
                        <img :src="item.is_spec==0?item.goods_picture:item.goods_spec.picture" class="l_g_image">
                        <div style="flex:1;width:0;">
                            <div class="l_g_name">{{item.goods_name}}</div>
                            <div class="l_g_spec" :style="{'opacity':item.is_spec==0?'0':'1'}">商品规格 {{item.is_spec==1?item.goods_spec.title:''}}</div>
                            <div class="l_g_buy">
                                <div class="l_g_buy_unitprice">￥{{item.unitprice}}</div>
                                <div class="l_g_buy_count">
                                    <div class="l_g_buy_count_btn" @click="handleOperate('-',index)">
                                        <img :src="imageHttp+(item.count>1?'reduce_7d7d7d.png':'reduce_B8B8B8.png')">
                                    </div>
                                    <div class="l_g_buy_count_num">{{item.count}}</div>
                                    <div class="l_g_buy_count_btn" @click="handleOperate('+',index)">
                                        <img :src="imageHttp+'plus_7d7d7d.png'">
                                    </div>
                                </div>
                                <div class="l_g_buy_aunitprice">￥{{(item.count * item.unitprice).toFixed(2)}}</div>
                            </div>
                        </div>
                        <img :src="imageHttp+'delete.png'" class="l_g_delete" @click="handleOperate('d',index)">
                    </div>
                </div>
                <div class="l_settlement">
                    <div class="l_settlement_top">
                        <div class="l_settlement_top_left">
                            <div class="row">
                                <div>总数：{{allnum}}</div>
                                <div>折扣：<span class="r_discount">{{memberinfo?memberinfo.discount+'折':'无'}}</span></div>
                            </div>
                            <div class="row" style="margin-top:12px;">
                                <div>合计：￥{{allprice.toFixed(2)}}</div>
                                <div>折后金额：<span class="r_discountprice">￥{{ordercal.toFixed(2)}}</span></div>
                            </div>
                        </div>
                        <div class="l_settlement_top_right" :style="{'background-color':main_color}" v-show="is_settle"
                        @click="is_settle = false">取消收款</div>
                    </div>
                    <div class="row" style="margin-top:12px;" v-show="!is_settle">
                        <div class="r_order" :style="{'--main_color':main_color}" @click="toHangOrder">挂单</div>
                        <div class="r_settle" :style="{'--main_color':main_color}" @click="toSettlement">收款￥{{ordercal.toFixed(2)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <goods :main_color="main_color" :isSpec="isSpec" @open-popup="openPopup"  @close-popup="closePopup"
            :order_goods="order_goods" @add-cart="addCart" @handle-spec="handleSpec" @preview-spec="previewSpec"
            v-show="!is_settle"></goods>
            <settlement :main_color="main_color" @open-popup="openPopup" @close-popup="closePopup" :is_settle="is_settle"
            :order_goods="order_goods" v-show="is_settle" @to-goods="toGoods" :allnum="allnum" :allprice="allprice" :memberinfo="memberinfo" :ordercal="ordercal" :isCoupon="isCoupon"></settlement>
        </div>
    </div>
  </div>
</template>

<script>
import goods from './components/goods.vue'
import settlement from './components/settlement.vue'
export default {
    components:{
        goods,
        settlement
    },
    inject:['reload'],
    data () {
        return {
            main_color:"",
            imageHttp:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/',
            memberinfo:null,
            membertel:'',
            isSpec:false,
            order_goods:[],
            ordercal:0,
            is_settle:false,
            isCoupon:false,
            goodscode:'',
            code:null,
        }
    },
    async created () {
        let res = await this.$axios.post(this.$api.cashier.GetColorScheme)
        if(res.code == 0){
            this.main_color = res.result.main_color
        }
        if(sessionStorage.getItem('hangorder')){
            let item = JSON.parse(sessionStorage.getItem('hangorder'))
            this.order_goods = item.goods
            this.memberinfo = item.memberinfo
            this.code = item.code
            this.calculateOrder()
        }
    },
    computed:{
        allnum(){
            return this.order_goods.reduce((total,item)=>{
                return total + item.count
            },0)
        },
        allprice(){
            return this.order_goods.reduce((total,item)=>{
                return total + item.count*item.unitprice
            },0)
        }
    },
    methods: {
        backToadmin () {
            this.$confirm('确定要退出收银？退出收银将清空挂单的所有订单', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let cashier_order = sessionStorage.getItem('cashier_order')
                if(cashier_order){
                    sessionStorage.removeItem('cashier_order')
                }
                this.$router.push({path:'/index'})
            }).catch(()=>{})
        },
        indexOut(){
            this.isSpec = false
            this.handleSpec()
            this.isCoupon = false
        },
        openPopup(str){
            this[str] = true
        },
        closePopup(str){
            this[str] = false
            if(str=='isSpec'){
                this.handleSpec()
                this.calculateOrder()
            }
        },
        searchMember(){
            this.$axios.post(this.$api.cashier.GetMemberInfo,{
                keyword:this.membertel
            })
            .then(res =>{
                if(res.code==0){
                    this.memberinfo = res.result
                    this.calculateOrder()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        addCart(obj){
            this.handleSpec()
            let flag = false
            let order_goods = [...this.order_goods]
            let n_obj =JSON.parse(obj)
            order_goods.forEach(item =>{
                let no_spec_C = n_obj.is_spec == 0 && item.is_spec == 0 && item.id == n_obj.id
                let is_spec_C = n_obj.is_spec == 1 && item.is_spec == 1 && item.goods_spec.id == n_obj.goods_spec.id
                if(no_spec_C || is_spec_C){
                    item.count ++
                    flag = true
                }
            })
            if(!flag){
                order_goods.push(n_obj)
            }
            this.isSpec = false
            this.order_goods = [...order_goods]
            this.calculateOrder()
        },
        deleteGoods(index){
            this.order_goods.splice(index,1)
        },
        clearGoods(){
            this.$confirm('是否清空所有商品和会员?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.order_goods = []
                this.memberinfo = null
                this.membertel = ''
                this.ordercal = 0
            }).catch(() => {});
        },
        handleOperate(str,index){
            let obj = this.order_goods[index]
            if(str=='+'){
                let stock = obj.is_spec==1?obj.goods_spec.stock:obj.goods_stock
                if(obj.count+1<=stock){
                    obj.count ++
                    this.calculateOrder()
                }else{
                    this.$message.error('商品库存不足')
                }
            }else if(str=='-'){
                if(obj.count>1){
                    obj.count --
                     this.calculateOrder()
                }
            }else{
                this.order_goods.splice(index,1)
                this.calculateOrder()
            }
        },
        calculateOrder(){
            if(this.order_goods.length>0){
				let arr = []
				this.order_goods.forEach(item=>{
					let obj = {
						goods_num:item.count,
						goods_id:item.id,
						goods_sku_id:item.is_spec===1?item.goods_spec.id:0
					}
					arr.push(obj)
				})
				let data = {
					user_id: this.memberinfo?this.memberinfo.user_id:0,
					is_calculate:1,
					goods:arr,
				}
                this.$axios.post(this.$api.cashier.Buycreate,data)
                .then(res =>{
                    if (res.code === 0) {
						this.ordercal = res.result.amount
					}else{
                        this.$message.error(res.msg)
                    }
                })
			}else{
				this.ordercal = 0
			}
        },
        // 整理商品订单
        handleSpec(){
            this.order_goods.forEach((item,index)=>{
				if(!item.is_checked){
					this.order_goods.splice(index,1)
				}
			})
        },
        // 预览多规格商品
        previewSpec(obj_p){
            let obj = JSON.parse(obj_p)
			let flag = false
			this.order_goods.forEach((item,index)=>{
				if(!item.is_checked){
					flag = true
					this.order_goods.splice(index,1,obj)
				}
			})
			if(!flag){
				this.order_goods.push(obj)
			}
            this.calculateOrder()
        },
        // 结算
        toSettlement(){
            if(this.order_goods.length>0){
                this.is_settle = true
            }else{
                this.$message.warning("您还没有选择商品")
            }
        },
        toGoods(){
            this.is_settle = false
        },
        //搜索商品
        searchGoods(){
            this.$axios.post(this.$api.cashier.ScanGoods,{
                coding:this.goodscode
            })
            .then(res =>{
                if (res.code === 0) {
                    let obj = res.result
                    obj.count = 1
                    obj.is_spec = obj.goods_spec.length==0?0:1
                    obj.unitprice = obj.is_spec==0?obj.goods_price:obj.goods_spec.price
                    obj.is_checked = true
                    this.addCart(JSON.stringify(obj))
                    this.goodscode = ''
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 挂单
        toHangOrder(){
            if(this.order_goods.length>0){
                let data = sessionStorage.getItem('cashier_order')
                if(data){
                    let order = JSON.parse(data)
                    let obj = {
                    	goods:this.order_goods,
                    	memberinfo:this.memberinfo,
                    	code:new Date()
                    }
                    if(this.code){
                    	order.forEach((item,index)=>{
                    		if(item.code===this.code){
                    			order.splice(index,1,obj)
                    		}
                    	})
                    }else{
                    	order.unshift(obj)
                    }
                    sessionStorage.setItem('cashier_order',JSON.stringify(order))
                }else{
                    let order = []
                    let obj = {
                        goods:this.order_goods,
                        memberinfo:this.memberinfo,
                        code:new Date()
                    }
                    order.push(obj)
                    sessionStorage.setItem('cashier_order',JSON.stringify(order))
                }
            }
            this.$router.push({path:'hangorder'})
        },
        refreshOrder(){
			if(this.code){
				let cashier_order = sessionStorage.getItem('cashier_order')
				let order = JSON.parse(cashier_order)
				order.forEach((item,index)=>{
					if(item.code===this.code){
						order.splice(index,1)
					}
				})
                sessionStorage.setItem('cashier_order',JSON.stringify(order))
			}
            sessionStorage.removeItem('hangorder')
			this.reload()
		},
    },
};
</script>

<style lang="less" scope>
.index {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 24px 24px;
     display: flex;
     flex-direction: column;

    .header {
        height: 90px;
        display: flex;
        align-items: center;
        .header_left{
            display: flex;
            align-items: center;
            flex: 0.58;
            margin-right: 20px;
            width: 0;
        }
        .header_right{
            display: flex;
            align-items: center;
            flex: 1;
            width: 0;
        }
        .membertel_label {
           font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #FFFFFF;
            flex-shrink: 0;
        }

        .membertel_input {
            flex: 1;
            height: 50px;
            background: rgba(0,0,0,0.26);
            box-sizing: border-box;
            padding: 0 17px;
            border: none;
            border-radius: 3px;
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: rgba(255,255,255,1);
            margin-right: 18px;
            &::-webkit-input-placeholder {
                font-size: 16px;
                font-family: Microsoft YaHei UI;
                font-weight: 400;
                color: rgba(255,255,255,0.6);
            }
            &:-webkit-autofill {
                -webkit-text-fill-color: #fff !important;
                caret-color: white;
            }
        }

        .membertel_search {
            width: 89px;
            height: 50px;
            background: rgba(0,0,0,0.26);
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 50px;
            border: none;
            border-radius: 3px;
        }
        .goods_inputbox{
            flex: 1;
            height: 50px;
            background: rgba(0,0,0,0.26);
            border-radius: 3px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 15px 0 24px;
            margin-right: 14px;
            .goods_search{
                width: 14px;
                height: 14px;
                margin-right: 14px;
            }
            .goods_input{
                 border: none;
                font-size: 16px;
                font-family: Microsoft YaHei UI;
                font-weight: 400;
                color: rgba(255,255,255,1);
                height: 100%;
                flex: 1;
                &::-webkit-input-placeholder {
                    font-size: 16px;
                    font-family: Microsoft YaHei UI;
                    font-weight: 400;
                    color: rgba(255,255,255,0.6);
                }
            }
            .goods_scan{
                 width: 16px;
                height: 16px;
            }
        }
        .logout{
            width: 144px;
            height: 50px;
            background: rgba(0,0,0,0.26);
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 50px;
            border: none;
            border-radius: 3px;
        }
    }

    .main {
        display: flex;
        width: 100%;
        overflow: hidden;
        flex: 1;
        height: 0;
        .left {
            flex:0.58;
            border-radius: 3px;
            background-color: #fff;
            margin-right: 20px;
            width:0;

            .left_box{
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            .l_member{
                // width: 100%;
                height: 80px;
                border-radius: 3px;
                position: relative;
                display: flex;
                justify-content: space-between;
                margin:0 20px 26px;
                .l_member_bg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.12;
                }
                .l_member_item{
                    width: 216px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .l_member_item_text{
                        font-size: 15px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                    }
                }
            }
            .l_title{
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                padding-bottom: 18px;
                border-bottom: 1px solid #F2F2F2;
                margin: 0 20px;
                .l_clear{
                    cursor: pointer;
                }
            }
            .l_goods{
                flex: 1;
                overflow-y: auto;
                height: 0;
                padding-left:20px;
                .l_goods_item{
                    display: flex;
                    padding: 25px 0;
                    align-items: center;
                    &:not(:last-child){
                        border-bottom: 1px solid #F2F2F2;
                    }
                    .l_g_image{
                        width: 98px;
                        height: 98px;
                        border-radius: 3px;
                        margin-right:10px;
                    }
                    .l_g_name{
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .l_g_spec{
                        font-size: 14px;
                        font-family: Microsoft YaHei UI;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 14px;
                    }
                    .l_g_buy{
                        margin-top: 8px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        .l_g_buy_unitprice{
                            font-size: 14px;
                            font-family: Microsoft YaHei UI;
                            font-weight: 400;
                            color: #333333;
                        }
                        .l_g_buy_count{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .l_g_buy_count_btn{
                                width: 45px;
                                height: 32px;
                                background: #F5F5F5;
                                border-radius: 2px 0px 0px 2px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                img{
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                            .l_g_buy_count_num{
                                font-size: 16px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #333333;
                                width: 53px;
                                height: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #FCFCFC;
                            }
                        }
                        .l_g_buy_aunitprice{
                            font-size: 16px;
                            font-family: Microsoft YaHei UI;
                            font-weight: 400;
                            color: #FF0000;
                            width: 80px;
                        }
                    }
                    .l_g_delete{
                        width: 21px;
                        height: 21px;
                        cursor: pointer;
                        margin: 0 20px 0 40px;
                    }
                }
            }
            .l_settlement{
                padding: 20px 20px 0;
                border-top: 1px solid #F2F6F9;
                .l_settlement_top{
                    display: flex;
                    align-items: center;
                }
                .l_settlement_top_left{
                    flex:1;
                }
                .l_settlement_top_right{
                    width: 160px;
                    height: 54px;
                    line-height: 54px;
                    color: #ffffff;
                    text-align: center;
                    margin-left:40px;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 20px;
                }
                .row{
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    font-family: Microsoft YaHei UI;
                    font-weight: 400;
                    color: #333333;
                }
                .r_discount{
                    color:#FF4800
                }
                .r_discountprice{
                    color: #FF0000;
                }
                .r_order{
                    width: 251px;
                    height: 65px;
                    border: 1px solid var(--main_color);
                    border-radius: 3px;
                    color:var(--main_color);
                    margin-right: 20px;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .r_settle{
                    width: 376px;
                    height: 64px;
                    background: var(--main_color);
                    border-radius: 3px;
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
        .right{
            flex: 1;
            border-radius: 3px;
            background-color: #fff;
            width: 0;
        }
    }
}

</style>

<template>
	<div class="settlement">
		<div class="s_header">
            <img :src="imageHttp+'back.png'" class="back" @click="toGoods">
            <div class="s_h_title">收款</div>
            <div class="order" :style="{'color':main_color}" @click="toHangOrder">挂单</div>
		</div>
        <div class="s_main">
            <div class="s_left">
                <div class="l_info">
                    <div class="info_row">
                        <div class="info_row_left info_row_text">当前时间</div>
                        <div class="info_row_text">{{nowDate}}</div>
                    </div>
                    <div class="info_row">
                        <div class="info_row_left info_row_text">商品数量</div>
                        <div class="info_row_text">{{allnum}}</div>
                    </div>
                    <div class="info_row">
                        <div class="info_row_left info_row_text">会员等级</div>
                        <div class="info_row_text">{{memberinfo?memberinfo.title:'无'}}</div>
                    </div>
                    <div class="info_row">
                        <div class="info_row_left info_row_text">会员名称</div>
                        <div class="info_row_text">{{memberinfo?memberinfo.name:'无'}}</div>
                    </div>
                    <div class="info_row">
                        <div class="info_row_left info_row_text">会员电话</div>
                        <div class="info_row_text">{{memberinfo?memberinfo.mobile:'无'}}</div>
                    </div>
                </div>
                <div class="order_row" style="margin-top: 30px;">
                    <div class="order_text">订单总额</div>
                    <div class="order_text">{{allprice.toFixed(2)}}元</div>
                </div>
                <div class="order_row2 order_coupon" :style="{'border-color':isCoupon?main_color:'#DADADA'}">
                    <div class="order_text order_row2_left">优惠券</div>
                    <div class="order_text order_row2_right" style="cursor: pointer;" @click.stop="handleCoupon">
                        <span>{{showCouponText}}</span>
                        <img :src="imageHttp+'back.png'" class="order_coupon_image"
                        :style="{'transform':isCoupon?'rotate(90deg)':'rotate(-90deg)'}">
                    </div>
                    <div class="couponlist" :style="{'height':isCoupon?'170px':0}"  @click.stop>
                        <div class="cl_box">
                            <div class="cl_item">
                                <span class="cl_item_canuse" @click="chooseCoupon(0, 1)"
                                :style="{'color':choosecouponId==0?main_color:'#666666'}">不使用优惠券</span>
                            </div>
                            <div class="cl_item" v-for="item in couponsList" :key="item.id">
                                <span :class="[item.is_use==1?'cl_item_canuse':'cl_item_nouse']"
                                :style="{'color':choosecouponId==item.id?main_color:'#666666'}"
                                @click="chooseCoupon(item.id,item.is_use)">{{getCoupontext(item)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order_row">
                    <div class="order_text">
                        优惠金额
                        <span style="color:#999999" v-if="memberinfo">{{memberinfo.discount}}折</span>
                    </div>
                    <div class="order_text">{{(useCouponPrice-useCardPrice).toFixed(2)}}元</div>
                </div>
                 <div class="order_row">
                    <div class="order_text">应收金额</div>
                    <div class="order_text" style="color:#FF0000;">{{useCardPrice.toFixed(2)}}元</div>
                </div>
                 <div class="order_row2" :style="{'border-color':is_inputbalance?main_color:'#DADADA'}">
                    <div class="order_text order_row2_left">会员卡余额抵扣</div>
                    <div class="order_text order_row2_right">
                        <input class="order_input" v-model="balance" @blur="handleBalance"
                        @focus="is_inputbalance = true">元
                    </div>
                </div>
            </div>
            <div class="s_right">
                <!-- 有配置支付 -->
                <template v-if="payment">
                    <div :class="['pay_type',payment.open_wechat_pay==1?'pay_wx':'']" style="margin-top: 48px;"
                    @click="openPay(payment.open_wechat_pay==1)">
                        <img :src="imageHttp+(payment.open_wechat_pay==1?'wechat_checked.png':'wechat_nocheck.png')" class="pay_type_image">
                        <div class="pay_type_text" :style="{'color':payment.open_wechat_pay==1?'#ffffff':'#666666 '}">微信支付</div>
                    </div>
                    <div :class="['pay_type',payment.open_ali_pay==1?'pay_ali':'']" style="margin-top: 40px;"
                    @click="openPay(payment.open_ali_pay==1)">
                        <img :src="imageHttp+(payment.open_ali_pay==1?'ali_checked.png':'ali_nocheck.png')" class="pay_type_image">
                        <div class="pay_type_text" :style="{'color':payment.open_ali_pay==1?'#ffffff':'#666666 '}">支付宝支付</div>
                    </div>
                    <div class="shishou_money">实收金额</div>
                    <div class="shishou_money2">￥{{(lastPrice>0?lastPrice:0).toFixed(2)}}</div>
                    <div class="scan">顾客打开付款码，收银员扫码</div>
                    <img :src="imageHttp+(payment.open_wechat_pay==1?'wechat_scan.png':'ali_scan.png')" class="scan_image">
                    <div class="huo">或</div>
                    <div class="payinput">
                        <input type="text" class="codeinput" placeholder="输入付款码付款" v-model="paycode" @keydown.enter="createOrder" ref="codeinput" :style="{'--main_color':main_color}">
                        <div class="confirmbtn" :style="{'background-color':main_color}" @click="createOrder">确认</div>
                    </div>
                    <div class="waittext">扫码枪扫描后请等待支付结果</div>
                </template>
                <!-- 没配置支付 -->
                <template v-else>
                    <img :src="imageHttp+'setting.png'" class="noset_image">
                    <div class="noset_text">暂未配置支付</div>
                    <div class="noset_text2">请管理员登录后台，配置微信/支付宝</div>
                    <div class="shishou_money">实收金额</div>
                    <div class="shishou_money2">￥{{(lastPrice>0?lastPrice:0).toFixed(2)}}</div>
                </template>
            </div>
        </div>
        <el-dialog :visible.sync="ispaySuccess" width="47%" title="成功" @close="closeDialog">
            <div class="dialog">
                <img :src="imageHttp+'p_success.png'" class="dialog_image">
                <div class="dialog_text">支付成功</div>
                <div class="dialog_text2">获取小票打印中...</div>
            </div>
        </el-dialog>
	</div>
</template>

<script>
    import { getDateformat } from '@/util/getDate';
	export default {
        props:['main_color','allnum','allprice','order_goods','memberinfo','ordercal','isCoupon','is_settle'],
		data() {
			return {
                imageHttp:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/',
                nowDate:"",
                couponsList:[],
                choosecouponId:0,
                balance:'0.00',
                paycode:'',
                payment:null,
                is_inputbalance:false,
                ispaySuccess:false,
                pay_sn:null
            };
		},
        created() {
            this.nowDate = getDateformat(new Date().getTime() / 1000);
            this.getPayment()
        },
        watch:{
            ordercal(){
                this.getCouponList()
                this.pay_sn = null
            },
            useCardPrice(val){
                let balance =  this.memberinfo?(val>this.memberinfo.balance?this.memberinfo.balance*1:val):0
                this.balance = balance.toFixed(2)
                this.pay_sn = null
            },
            is_settle(val){
                if(val){
                    this.$refs.codeinput.focus()
                    this.getCouponList()
                    this.pay_sn = null
                }
            },
            balance(){
                this.pay_sn = null
            }
        },
        computed:{
            showCouponText(){
                let str = '不使用优惠券'
                this.couponsList.forEach(item => {
					if (item.id === this.choosecouponId) {
						str = this.getCoupontext(item)
					}
				})
                return str
            },
             // 使用优惠券后金额
            useCouponPrice(){
				let discount = 0
				this.couponsList.forEach(item => {
					if (item.id === this.choosecouponId) {
						discount = (item.type === 1 || item.type === 2) ? item.subtract : Math.floor(this.allprice * (10 - item.discount) * 10) / 100
					}
				})
				return this.allprice - discount > 0 ? (this.allprice - discount) : 0
            },
            // 使用会员卡后金额
			useCardPrice(){
				let discount = this.memberinfo?this.memberinfo.discount*0.1:1
				return this.useCouponPrice*discount
			},
            lastPrice(){
                return (this.useCardPrice - this.balance*1).toFixed(2)*1
            }
        },
		methods: {
            toGoods(){
                this.$emit('to-goods')
            },
            handleCoupon(){
                if(this.isCoupon){
                    this.$emit('close-popup','isCoupon')
                }else{
                    this.$emit('open-popup','isCoupon')
                }
            },
            // 获取优惠券列表
            getCouponList(){
                if(this.memberinfo){
                    this.$axios.post(this.$api.cashier.GetCouponList,{
                        user_id: this.memberinfo.user_id,
					    price: this.allprice
                    })
                    .then(res =>{
                        if(res.code==0){
                            this.couponsList = res.result
							let discount = []
							this.couponsList.forEach(item => {
								if(item.is_use===1){
									let price = (item.type === 1 || item.type === 2) ? item.subtract : Math.floor(this.allprice * (10 - item.discount) * 10) / 100
									discount.push({
										id:item.id,
										price:price*1
									})
								}
							})
							if(discount.length>0){
								let max = discount[0];
								for (let i = 0; i < discount.length - 1; i++) {
									max = max.price < discount[i+1].price ? discount[i+1] : max
								}
								this.choosecouponId = max.id
							}else{
                                this.choosecouponId = 0
                            }
                        }
                    })
                }
            },
            // 优惠券文字
            getCoupontext(item){
                if(item.type==1){
                    return '满'+ item.consume_full + '元减' + item.subtract +'元'
                }else if(item.type==2){
                    return '立减'+ item.subtract
                }else{
                    return '满' + item.consume_full + '元' + item.discount + '折'
                }
            },
            handleBalance(){
                if(this.memberinfo){
                    let pay = this.useCardPrice>this.memberinfo.balance?this.memberinfo.balance:this.useCardPrice
                    this.balance = (pay>this.balance?this.balance*1:pay*1).toFixed(2)
                }else{
                    this.balance = '0.00'
                }
                this.is_inputbalance = false
            },
            createOrder(){
                if(this.paycode || this.lastPrice == 0){
                    if(this.pay_sn){
                        this.payOrder(this.pay_sn)
                    }else{
                        let arr = []
                        this.order_goods.forEach(item => {
                            let obj = {
                                goods_num: item.count,
                                goods_id: item.id,
                                goods_sku_id: item.is_spec === 1 ? item.goods_spec.id : 0
                            }
                            arr.push(obj)
                        })
                        this.$axios.post(this.$api.cashier.Buycreate,{
                            user_id: this.memberinfo ? this.memberinfo.user_id : 0,
                            coupon_id: this.choosecouponId,
                            balance_deduction: this.balance * 1,
                            is_calculate: 0,
                            goods: arr
                        })
                        .then(res =>{
                            if (res.code === 0) {
                                if (this.lastPrice > 0) {
                                    this.payOrder(res.result.pay_sn)
                                } else {
                                    this.ispaySuccess = true
                                }
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
                    }
                }else{
                    this.$message.warning('收银员扫码')
                    this.$refs.codeinput.focus()
                }
            },
            getPayment(){
                 this.$axios.post(this.$api.cashier.GetPayment)
                .then(res =>{
                    if (res.code === 0) {
                        this.payment = res.result
                    }
                })
            },
            // 选择优惠券
            chooseCoupon(id,is_use){
                if (is_use === 1) {
                    this.choosecouponId = id
                    this.$emit('close-popup','isCoupon')
                }
            },
            payOrder(pay_sn){
                this.$axios.post(this.$api.cashier.BayPay,{
                    pay_sn: pay_sn,
                    auth_code: this.paycode
                })
				.then(res => {
					if (res.code === 0) {
						this.ispaySuccess = true
						this.paycode = ''
					}else{
                        this.$message.error(res.msg)
                        this.paycode = ''
                        this.pay_sn = pay_sn
                    }
				})
            },
            closeDialog(){
                 this.$parent.refreshOrder()
            },
            // 挂单
            toHangOrder(){
                this.$parent.toHangOrder()
            },
            openPay(flag){
                if(flag){
                    this.createOrder()
                }
            }
		}
	};
</script>

<style lang="less" scope>
	.settlement {
        .s_header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            border-bottom: 1px solid #E9E9E9;
            .back{
                width: 18px;
                height: 18px;
                margin: 0 25px;
                cursor: pointer;
            }
            .s_h_title{
                font-size: 17px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
            }
            .order{
                font-size: 17px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                margin: 0 39px;
                cursor: pointer;
            }
        }
        .s_main{
            display: flex;
            .s_left{
                margin: 30px 6% 0 4.2%;
                flex: 1.15;
                .l_info{
                    // width: 530px;
                    height: 230px;
                    border: 1px solid #DADADA;
                    border-radius: 3px;
                    padding: 26px 64px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .info_row{
                        display: flex;
                        .info_row_left{
                            width: 110px;
                            letter-spacing: 1px;
                        }
                    }
                    .info_row_text{
                        font-size: 15px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                .order_row{
                    // width: 530px;
                    height: 64px;
                    background: #F2F3F5;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    padding: 0 30px;
                    justify-content: space-between;
                    box-sizing: border-box;
                    margin-top: 20px;
                }
                .order_row2{
                    // width: 530px;
                    height: 62px;
                    background: #FFFFFF;
                    border: 1px solid #DADADA;
                    border-radius: 3px;
                    display: flex;
                    margin-top: 20px;
                    justify-content: space-between;
                    .order_row2_left{
                        padding: 0 30px;
                        border-right: 1px solid #DADADA;
                        background-color: #F2F3F5;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                    }
                    .order_row2_right{
                        margin-right: 30px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                    }
                    .order_input{
                        border: none;
                        text-align: right;
                        width: 100%;
                    }
                    .order_coupon_image{
                        width: 14px;
                        height: 14px;
                        margin-left:8px;
                        transform: rotate(-90deg);
                        transition: transform 0.2s;
                    }
                }
                .order_coupon{
                    position: relative;
                    .couponlist{
                        position: absolute;
                        top: 64px;
                        left: 0;
                        right: 0;
                        height: 240px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 8px 0px rgba(183, 183, 183, 0.34);
                        transition: height 0.2s;
                        overflow-y: auto;
                        .cl_box{
                            padding: 20px 50px 20px 0;
                        }
                        .cl_item{
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #666666;
                            text-align: right;
                            &:not(:last-child){
                                margin-bottom: 20px;
                            }
                        }
                        .cl_item_canuse{
                            cursor: pointer;
                        }
                        .cl_item_nouse{
                            opacity: 0.5;
                        }
                    }
                }
            }
            .s_right{
                flex: 1;
                margin-right: 68px;
                text-align: center;
                .pay_type{
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #DADADA;
                }
                .pay_wx{
                    background-color: #09BB07;
                    cursor: pointer;
                }
                .pay_ali{
                    background-color: #2D72FF;
                    cursor: pointer;
                }
                .pay_type_image{
                    width: 40px;
                    height: 40px;
                    margin-right: 24px;
                }
                .pay_type_text{
                    font-size: 22px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    letter-spacing: 1px;
                }
                .shishou_money{
                    font-size: 17px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    margin-top: 24px;
                    text-align: center;
                }
                .shishou_money2{
                    width: 90%;
                    height: 70px;
                    border: 1px solid #DADADA;
                    border-radius: 3px;
                    font-size: 28px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FF0000;
                    margin: 24px auto 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .scan{
                    font-size: 17px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #999999;
                    margin-top: 24px;
                    text-align: center;
                }
                .scan_image{
                    width: 170px;
                    height: 100px;
                    margin-top: 20px;
                }
                .huo{
                    font-size: 17px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #666666;
                    position: relative;
                    margin-top: 14px;
                    &::before{
                        content: "";
                        position: absolute;
                        left: 49px;
                        top:10px;
                        height: 2px;
                        width: 32%;
                        background-color: #ECECEC;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        right: 49px;
                        top:10px;
                        width: 32%;
                        height: 2px;
                        background-color: #ECECEC;
                    }
                }
                .payinput{
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .codeinput{
                        width: 270px;
                        height: 53px;
                        background: #F2F2F2;
                        border-radius: 2px;
                        padding: 0 26px;
                        box-sizing: border-box;
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        margin-right: 12px;
                        border: none;
                         &::-webkit-input-placeholder {
                            color: #999999;
                        }
                        &:focus{
                            border:1px solid var(--main_color)
                        }
                    }
                    .confirmbtn{
                        width: 83px;
                        height: 53px;
                        border-radius: 2px;
                        line-height: 53px;
                        text-align: center;
                        font-size: 17px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
                .waittext{
                     margin-top: 28px;
                     font-size: 15px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #999999;
                }
                .noset_image{
                    width: 95%;
                    height: auto;
                    margin-top:70px;
                }
                .noset_text{
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #666666;
                    margin-top:20px;
                }
                .noset_text2{
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #999999;
                    margin-top:10px;
                    margin-bottom:100px;
                }
            }
        }
        .dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .dialog_image{
                width: 30%;
                height: auto;
                margin-top:70px;
            }
            .dialog_text{
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
                margin-top:40px;
            }
            .dialog_text2{
                font-size: 17px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #666666;
                margin-top: 30px;
                margin-bottom:40px
            }
        }
	}
</style>
